
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import PoHome from "@/views/admin/order/_id/PoHome.vue";
import PoAway from "@/views/admin/order/_id/PoAway.vue";
import { IOrder, IOrderPrintRequest } from "@/types/order";
import { ISupplier } from "@/types/supplier";
import { ISupplierStaff } from "@/types/supplier_staff";

@Component({ components: { PoHome, PoAway } })
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // order
  @Action("order/adminGet")
  public getOrder!: (id: number) => Promise<boolean>;

  @Action("order/adminUpdateDeliveryDate")
  public updateDeliveryDate!: (data: {
    order_id: number;
    params: {
      is_asap: number;
      desired_delivery_date: string | null;
      fixed_delivery_date: string | null;
    };
  }) => Promise<boolean>;

  @Getter("order/single")
  public order!: IOrder;

  @Mutation("order/clear")
  public clearOrder!: () => void;

  //supplier
  @Action("supplier/adminGet")
  public getSupplier!: (supplier_id: number) => Promise<boolean>;

  @Getter("supplier/single")
  public supplier!: ISupplier;

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  // supplier_staff
  @Action("supplier_staff/adminGet")
  public getSupplierStaff!: (supplier_staff_id: number) => Promise<boolean>;

  @Getter("supplier_staff/single")
  public supplier_staff!: ISupplierStaff;

  @Mutation("supplier_staff/clear")
  public clearSupplierStaff!: () => void;

  // pdf
  @Action("pdf/adminOrderHomePDF")
  public printOrderPdf!: (params: IOrderPrintRequest) => boolean;

  // fields
  public order_id = 0;
  public currency = "￥";
  public supplier_staffs = {};
  public isOpen = false;
  public po_home_dialog = false;
  public po_away_dialog = false;
  public update_is_asap = 0;
  public update_desired_delivery_date: string | null = "";
  public update_fixed_delivery_date: string | null = "";
  public delivery_date_dialog = false;
  public is_update_fixed_delivery_date = false;
  public menu = {
    update_desired_delivery_date: false,
    update_fixed_delivery_date: false
  };

  public async created() {
    this.order_id = Number(this.$route.params.order_id);

    this.clearOrder();
    this.clearSupplier();
    this.clearSupplierStaff();

    await this.getOrder(this.order_id);

    await this.getSupplier(this.order.supplier_id);

    if (this.order.supplier_staff) {
      this.getSupplierStaff(this.order.supplier_staff);
    }

    this.currencyFlagSelected();

    if (this.order.currency_type != 1) {
      this.movement_headers.splice(this.movement_headers.length - 1, 0, {
        text: "為替レート",
        value: "exchange_rate",
        sortable: false,
        align: "end"
      });
    }

    // 修正用希望納品日、入荷予定日セット
    this.update_is_asap = this.order.is_asap;
    this.update_desired_delivery_date = this.order.desired_delivery_date;
    this.update_fixed_delivery_date = this.order.fixed_delivery_date;
  }

  // 発注商品ヘッダー
  public headers = [
    {
      text: "",
      value: "division_storing",
      class: "px-0",
      cellClass: "px-0",
      sortable: false
    },
    {
      text: "商品名",
      value: "product_name",
      sortable: false
    },
    {
      text: "品番",
      value: "product_code",
      sortable: false
    },
    {
      text: "発注商品メモ",
      value: "content",
      sortable: false
    },
    {
      text: "発注数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "残入荷数量",
      value: "remaining_quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "発注単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "発注単価合計",
      value: "amount",
      sortable: false,
      align: "end"
    }
  ];

  // 諸経費ヘッダー
  public price_tag_headers = [
    {
      text: "",
      value: "",
      sortable: false,
      width: "55%"
    },
    {
      text: "諸経費名",
      value: "price_tag_name",
      sortable: false
    },
    {
      text: "サプライヤ品番",
      value: "supplier_pn",
      sortable: false
    },
    {
      text: "注文番号",
      value: "price_tag_no",
      sortable: false
    },
    {
      text: "記載金額",
      value: "price",
      sortable: false,
      align: "end"
    }
  ];

  // 入荷記録ヘッダー
  public movement_headers = [
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "ロットNo.", value: "lot_no", sortable: false },
    {
      text: "入荷日",
      value: "movement_date",
      sortable: false
    },
    {
      text: "入荷数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "輸送費",
      value: "transport_price",
      sortable: false,
      align: "end"
    }
  ];

  // 郵便番号整形
  public postalcode_insert(val: string) {
    return val ? "〒" + val.slice(0, 3) + "-" + val.slice(3, val.length) : "";
  }

  // 消費税額計算
  public tax_calc(val: IOrder) {
    return this.order.currency_type != 1
      ? Number((val.subtotal * (val.tax_rate / 100)).toFixed(4)).toLocaleString(
          undefined,
          {
            maximumFractionDigits: 4
          }
        )
      : Number(
          (val.subtotal * (val.tax_rate / 100)).toFixed(2)
        ).toLocaleString();
  }

  //通貨単位選択
  public currencyFlagSelected() {
    if (this.order.currency_type == 1) this.currency = "￥";
    if (this.order.currency_type == 2) this.currency = "＄";
    if (this.order.currency_type == 3) this.currency = "€";
  }

  // 輸送費合計計算
  get total_transport_price() {
    return this.order.movement_list.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        (currentValue.quantity || 0) * currentValue.transport_unit_price
      );
    }, 0);
  }

  // 希望納品日、入荷予定日更新
  public async desiredDeliveryDateSubmit(
    is_update_fixed_delivery_date: boolean
  ) {
    this.delivery_date_dialog = false;

    await this.updateDeliveryDate({
      order_id: this.order_id,
      params: {
        is_asap: is_update_fixed_delivery_date
          ? this.order.is_asap
          : this.update_is_asap,
        desired_delivery_date: is_update_fixed_delivery_date
          ? this.order.desired_delivery_date
          : this.update_desired_delivery_date,
        fixed_delivery_date: is_update_fixed_delivery_date
          ? this.update_fixed_delivery_date
          : this.order.fixed_delivery_date
      }
    });

    this.$router.go(0);
  }

  public closeDesiredDeliveryDateDialog() {
    this.delivery_date_dialog = false;
    this.update_is_asap = this.order.is_asap;
    this.update_desired_delivery_date = this.order.desired_delivery_date;
    this.update_fixed_delivery_date = this.order.fixed_delivery_date;
  }
}
